.invoice {
  @include card-base;
  padding: 40px;

  .invoice-title {
    .invoice-number {
      float: right;
      font-size: 20px;
      font-weight: 700;
      margin-top: -45px;
    }
  }

  hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top-color: #f9f9f9;
  }

  .invoice-detail-item {
    margin-bottom: 15px;

    .invoice-detail-name {
      letter-spacing: 0.3px;
      color: color(muted);
      margin-bottom: 4px;
    }

    .invoice-detail-value {
      font-size: 18px;
      color: color(fontdark);
      font-weight: 700;

      &.invoice-detail-value-lg {
        font-size: 24px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .table-invoice {
    table {
      min-width: 800px;
    }
  }
}
