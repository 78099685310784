.chart-shadow2 {
  -webkit-filter: drop-shadow(0 -6px 4px rgba(106, 141, 247, 0.5));
  filter: drop-shadow(0 -6px 4px rgba(106, 141, 247, 0.5));
}

.chart-shadow {
  -webkit-filter: drop-shadow(0px 9px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 9px 2px rgba(0, 0, 0, 0.3));
}

.chartsh {
  height: 16rem;
}

/* Morris */
.morris-hover {
  position: absolute;
  z-index: 1000;
}

.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

/* amchart */

#barChart,
#barImg,
#lineChart,
#donutChart,
#pieChart,
#gaugeChart,
#radialLineChart,
#dumbbellPlotChart {
  width: 100%;
  height: 400px;
}

#amChartBar,
#amChartCylinder,
#amChartGauge {
  width: 100%;
  height: 200px;
}

#amChartLineHome,
#amChartCylinderHome {
  width: 100%;
  height: 300px;
}

#mapBubble {
  width: 100%;
  height: 500px;
}

#amChartLineWidget {
  width: 100%;
  height: 350px;
}
.amChartHeight {
  width: 100%;
  height: 400px;
}

.amcharts-export-menu-top-right {
  top: 10px;
  right: 0;
}

// apex chart
.apexcharts-xaxis-label {
  fill: #000000;
}
