.product-item {
  text-align: center;

  .product-image {
    display: inline-block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .product-name {
    color: color(fontdark);
    font-weight: 700;
    margin-bottom: 3px;
  }

  .product-review {
    color: color(warning);
    margin-bottom: 3px;
  }

  .product-cta {
    margin-top: 5px;

    a {
      margin-top: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
