/*
 *  Document   : _task.scss
 *  Author     : RedStar Template
 *  Description: This scss file for task style classes
 */

.task-actionlist {
  position: absolute;
  right: 0px;
  top: 11px;

  a {
    height: 24px;
    width: 24px;
    display: inline-block;
    float: left;

    i {
      height: 24px;
      width: 24px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      color: #ccc;
    }

    &:hover i {
      color: #666;
    }
  }
}

.line-through {
  text-decoration: line-through;
}

.task-action-bar {
  margin-top: 20px;
}

.task-check {
  width: 20px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;

  input[type="checkbox"] {
    visibility: hidden;
  }

  label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border-radius: 2px;
  }
}

.task-done i {
  font-size: 14px;
}

.task-remove i {
  font-size: 10px;
}

.inbox-small-cells .task-check input[type="checkbox"] {
  visibility: hidden;
}
.task-selected {
  text-decoration: line-through;
}
.task-title {
  font-size: 16px;
  font-weight: 700;
  color: #5e5f67;
  margin-bottom: 2px;
  transition: all 0.35s ease;
}
.task-date {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold !important;
  color: #85899c !important;
  line-height: 1.3;
}
.task-details {
  font-size: 14px;
  margin-bottom: 0;
  color: #848484 !important ;
  font-weight: 600 !important;
  transition: all 0.35s ease;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: calc(100vw - 900px);
}
#taskTbl {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  td {
    border-bottom: 1px solid #ececec;
  }
}
#taskTbl {
  .btn-view {
    cursor: pointer;
    color: #000000;
    i {
      font-size: 16px;
    }
  }
  .btn-delete {
    cursor: pointer;
    color: #f90a21;
    i {
      font-size: 16px;
    }
  }
}
#title-error,
#date-error {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}
