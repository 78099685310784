/*
 *  Document   : _calendar.scss
 *  Author     : RedStar Theme
 *  Description: This scss file for style related to calendar app
 */

#event_title {
  font-size: 1.2rem;
}

#calendar {
  float: right;
  width: 100%;
}

#external-events {
  .fc-event {
    padding: 5px 10px;
    font-size: 14px;
    margin-bottom: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: move;
  }
  .form-check .form-check-label {
    font-size: 14px;
  }
}
.cal-event {
  display: inline-block !important;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  padding: 0;
}
.fc-state-active {
  background: #a389d4 !important;
  color: #fff;
}
.fc-day-grid-event {
  color: white !important;
  text-align: center;
}
.fc-event-primary {
  border: none;
  background-color: #007bff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 154, 255, 0.4);
}
.fc-event-warning {
  border: none;
  background-color: #ff9800;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
}
.fc-event-success {
  border: none;
  position: relative;
  background-color: #53b958;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  font-weight: 400;
}
.fc-event-danger {
  border: none;
  background-color: #f9483b;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
}
.fc-event-info {
  border: none;
  position: relative;
  background-color: #03c5de;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  font-weight: 400;
}
.fc-event-default {
  background: #007bff;
  border: 1px solid #007bff;
}
.fc-state-default {
  border: 1px solid #eee;
  background: transparent !important;
  color: #7e869d;
  border-radius: 0;
}
.catLbl {
  font-weight: 600;
  color: #34395e;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
.fc-button-primary {
  background-color: #ffffff;
  border: 1px solid #eee;
  color: #7e869d;
  border-radius: 5px !important;
  margin-right: 8px;
  &:disabled {
    color: #fff;
    background-color: #6777ef;
    border-color: #6777ef;
  }
  &:hover {
    color: #868181;
    background-color: #e1e0e0;
    border-color: #e1e0e0;
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(76, 91, 106, 0.5);
  }
  &:not(:disabled) {
    &.fc-button-active {
      color: #fff;
      background-color: #6777ef;
      border-color: #6777ef;
    }
    &:active {
      color: #fff;
      background-color: #6777ef;
      border-color: #6777ef;
    }
  }
}
.fc-color-picker {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    float: left;
    font-size: 30px;
    margin-right: 5px;
    line-height: 30px;
  }
}
.fc-unthemed td.fc-today {
  background: #edeef3;
}
.fc-content-skeleton thead {
  border-bottom: none;
}
.fc-unthemed {
  td.fc-today {
    background: transparent;
    a {
      background: #6777ef;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 21px;
      margin: 4px 0;
      float: none !important;
    }
  }
  td.fc-day-top {
    text-align: center;
    .fc-day-number {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 21px;
      margin: 4px 0;
      font-size: 12px;
      border-radius: 50%;
      float: none !important;
    }
  }
}
#starts-at-error,
#ends-at-error {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}
