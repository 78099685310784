.note-editor.note-frame {
  border-radius: 3px;
  border: 1px solid #ededed;
  box-shadow: none;
}

.note-toolbar {
  padding: 0 0 5px 5px !important;
  position: relative !important;

  &.card-header {
    height: auto;
    display: block;
    min-height: auto;
  }

  .note-btn {
    font-size: 12px;
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
  }
}
