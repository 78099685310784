.pwindicator {
  margin-top: 4px;
  width: 150px;

  .bar {
    height: 2px;
  }
}

.pw-very-weak {
  .bar {
    background: #d00;
    width: 30px;
  }

  .label {
    color: #d00;
  }
}

.pw-weak {
  .bar {
    background: #d00;
    width: 60px;
  }

  .label {
    color: #d00;
  }
}

.pw-mediocre {
  .bar {
    background: #f3f01a;
    width: 90px;
  }

  .label {
    color: #f3f01a;
  }
}

.pw-strong {
  .bar {
    background: #f3b31a;
    width: 120px;
  }

  .label {
    color: #f3b31a;
  }
}

.pw-very-strong {
  .bar {
    background: #0d0;
    width: 150px;
  }

  .label {
    color: #0d0;
  }
}
