.user-list {
  .user-list-img {
    border-radius: 6px;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    width: 35px;
  }
}

.support-ticket {
  .user-img {
    border-radius: 6px;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    width: 35px;
  }
}
