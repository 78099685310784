.contact-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #bbbbbb;
  -webkit-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
  -moz-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
  -ms-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
  box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
  text-shadow: 0 0 black;
}
.contact-list {
  padding: 0px 0px 10px 0px;
  margin: 15px 0px 15px 0px;
  border-bottom: 1px solid #dcdbdb;
  li {
    list-style-type: none;
    position: relative;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;

    .feather {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      text-align: center;
      fill: rgba(75, 75, 90, 0.12);
    }
    a {
      width: 100%;
      border-radius: 30px;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: #60686f;
    }
    & a.active {
      color: #000000;
      background-color: rgba(146, 144, 144, 0.28);
    }
  }
  .nav-item .nav-link {
    padding: 5px 0px 5px 10px;
  }
  .counter {
    float: right;
    margin-right: 20px;
    color: #6777ef;
    font-weight: bold;
  }
}
.contact-leftmenu {
  .btnAdd {
    margin: 5px 20px 30px 5px;
    text-align: center;
  }
}
#contactTbl {
  .btn-view {
    cursor: pointer;
    color: #6777ef;
    i {
      font-size: 16px;
    }
  }
  .btn-delete {
    cursor: pointer;
    color: #f90a21;
    i {
      font-size: 16px;
    }
  }
}
.contact-search {
  input[type="text"] {
    position: relative;
    padding: 10px 40px 10px 20px;
    width: 35%;
    color: #525252;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 2px;
    border-radius: 5px;
    background-color: white;
    outline: none;
    border: 1px solid #a5a5a5;
    margin-bottom: 15px;
  }

  i {
    position: relative;
    left: -37px;
    color: #8233c5;
  }
}
@include media-breakpoint-down(md) {
  .contact-search {
    input[type="text"] {
      width: 80%;
    }
  }
}
#name-error,
#email-error,
#phone-error,
#address-error {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}
